<template>
  <v-card elevation="0" :class="[`type-${type}`]">
    <v-toolbar dense flat class="pb-0">
      <v-avatar color="accent" size="26">
        <span class="title secondary--text headline">{{ item.priority }}</span>
      </v-avatar>
      <span class="ml-2 grey--text subtitle-2"
        >第{{ item.priority }}執行順序</span
      >
      <v-spacer></v-spacer>
      <v-btn
        v-if="type !== 'sort'"
        icon
        small
        class="mr-2"
        color="accent"
        @click="removeEvent()"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="!edit && type !== 'sort'"
        icon
        small
        class="ma-0"
        color="accent"
        @click="edit = true"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="edit"
        icon
        small
        class="ma-0 secondary"
        color="accent"
        :disabled="
          !item.name ||
          item.groupId.length === 0 ||
          (!item.action.offloadingmode && item.action.offloadingmode !== 0)
        "
        @click="saveEvent()"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-title class="pt-0">
      <span
        v-if="!edit"
        style="min-height: 40px"
        class="ml-2 accent--text font-weight-bold"
        >{{ item.name }}</span
      >
      <v-text-field
        v-else
        v-model="item.name"
        placeholder="請輸入方案名稱"
        dense
        outlined
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <eventGroup
        v-model="item.groupId"
        :hideGroups="hideGroups"
        :type="item.type"
      />
      <eventAction v-model="item.action" :type="item.type" />
    </v-card-text>
  </v-card>
</template>

<script>
import eventAction from '@/views/school/unloading/eventAction'
import eventGroup from '@/views/school/unloading/eventGroup'

export default {
  name: 'UnloadingEventCard',
  mixins: [],
  components: {
    eventAction,
    eventGroup
  },
  props: {
    value: {
      type: Object
    },
    type: {
      type: String,
      default: 'show'
    },
    unloadingList: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      item: this.value,
      edit: false
    }
  },
  computed: {
    hideGroups() {
      const hideGroups = []
      this.unloadingList.forEach((item) => {
        if (this.value === item) {
          return
        }
        item.groupId.forEach((_groupId) => {
          hideGroups.push(_groupId)
        })
      })
      return hideGroups
    }
  },
  watch: {
    value(val) {
      this.item = val
    },
    edit(val) {
      this.$emit('update:type', val ? 'edit' : 'show')
    }
  },
  created: function () {},
  mounted: function () {
    this.edit = this.type === 'edit'
  },
  destroyed() {},
  methods: {
    saveEvent() {
      this.edit = false
      this.$emit('save')
      this.$emit('input', this.item)
    },
    removeEvent() {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
.type-sort {
  border: 3px dashed var(--v-primary-base);
}
.type-edit::v-deep {
  fieldset,
  .v-card.v-sheet--outlined {
    border-color: #a1b8d9;
  }

  .v-list-item__title,
  .v-select__selection,
  input {
    color: var(--v-accent-base);
  }
}
</style>
