<template>
  <div class="mt-4">
    <v-row v-if="type === 'edit'">
      <v-col>
        <v-select
          v-model="event"
          :items="eventOptions"
          placeholder="選擇執行動作"
          hide-details
          outlined
        ></v-select>
      </v-col>
      <v-col v-if="event === 0">
        <v-select
          placeholder="請選擇溫度"
          v-model="temperature"
          :items="temperatureOption"
          item-value="acResponseValue"
          hide-details
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-card v-else elevation="0" outlined class="mt-2">
      <v-list dense class="event-list">
        <v-list-item>
          <v-list-item-icon class="mr-0">
            <v-icon color="accent">mdi-circle-small</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="accent--text subtitle-1">{{
              eventText
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'UnloadingEventAction',
  mixins: [],
  components: {},
  props: {
    value: {
      type: Object
    },
    type: {
      type: String,
      default: 'show'
    }
  },
  data: function () {
    return {
      event: null,
      temperature: null,
      eventOptions: [
        {
          text: '溫度調整',
          value: 0
        },
        {
          text: '送風模式',
          value: 1
        },
        {
          text: '關閉冷氣',
          value: 2
        }
      ]
    }
  },
  computed: {
    eventText() {
      if (!this.event && this.event !== 0) {
        return null
      }

      const event = this.eventOptions.find(
        (element) => element.value === this.event
      )
      if (!event) {
        return null
      }

      if (this.event === 0) {
        const temperature = this.temperatureOption.find(
          (element) => element.acResponseValue === this.temperature
        )
        return event.text + '為' + temperature.text
      }
      return event.text
    },
    temperatureOption() {
      return this.$store.getters['ac/temperatureOption'].map((item) => {
        item.acResponseValue = Number(item.acResponseValue)
        return item
      })
    }
  },
  watch: {
    value(val) {
      this.initEvent()
    },
    event(val) {
      this.commit()
    },
    temperature(val) {
      this.commit()
    }
  },
  created: function () {},
  mounted: function () {
    this.initEvent()
  },
  destroyed() {},
  methods: {
    initEvent() {
      this.event = this.value.offloadingmode
      this.temperature = this.value.temperature
    },
    commit() {
      const returnEvent = {}
      returnEvent.offloadingmode = this.event
      switch (this.event) {
        case 0:
          returnEvent.temperature = this.temperature
          break
      }
      this.$emit('input', returnEvent)
    }
  }
}
</script>

<style lang="scss" scoped>
.event-list {
  height: 54px;
}
</style>
