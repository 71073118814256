var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-5"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_vm._v(" 警戒卸載值： ")]),_c('v-col',{staticStyle:{"width":"100px"},attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"mt-5",attrs:{"dense":"","solo":"","rules":[
            function (v) { return !_vm.demand.recoverycapacity || !!v || '必填'; },
            function (v) { return !isNaN(v) || '請輸入數字'; },
            function (v) { return Number(_vm.demand.recoverycapacity) < Number(v) || '須大於復歸值'; }
          ],"disabled":!_vm.demandEdit},model:{value:(_vm.demand.alarmcapacity),callback:function ($$v) {_vm.$set(_vm.demand, "alarmcapacity", $$v)},expression:"demand.alarmcapacity"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_vm._v(" kW ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_vm._v(" 復歸值： ")]),_c('v-col',{staticStyle:{"width":"100px"},attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"mt-5",attrs:{"dense":"","solo":"","rules":[
            function (v) { return !_vm.demand.alarmcapacity || !!v || '必填'; },
            function (v) { return !isNaN(v) || '請輸入數字'; },
            function (v) { return Number(_vm.demand.alarmcapacity) > Number(v) || '須小於警戒值'; }
          ],"disabled":!_vm.demandEdit},model:{value:(_vm.demand.recoverycapacity),callback:function ($$v) {_vm.$set(_vm.demand, "recoverycapacity", $$v)},expression:"demand.recoverycapacity"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_vm._v(" kW ")]),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.demandEdit)?_c('v-btn',{attrs:{"color":"success","disabled":!_vm.valid},on:{"click":function($event){_vm.setDemand()
            _vm.demandEdit = false}}},[_vm._v("儲存 ")]):_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.demandEdit = true}}},[_vm._v("修改 ")])],1)],1)],1),_c('div',{staticClass:"mt-6"},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"pa-0",staticStyle:{"height":"auto"},attrs:{"color":"primary","text":"","x-large":"","disabled":_vm.sort},on:{"click":function($event){return _vm.createEvent()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" 新增卸載方案 ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disabledSortBtn},on:{"click":function($event){_vm.sort = !_vm.sort}}},[_vm._v(" "+_vm._s(_vm.sort ? '關閉執行順序' : '編輯執行順序')+" ")])],1)],1),_c('draggable',_vm._b({on:{"end":function($event){return _vm.setSort()}},model:{value:(_vm.unloadingList),callback:function ($$v) {_vm.unloadingList=$$v},expression:"unloadingList"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{staticClass:"row",attrs:{"type":"transition"}},_vm._l((_vm.unloadingList),function(item,key){return _c('v-col',{key:("" + key),attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('eventCard',{attrs:{"value":item,"unloadingList":_vm.unloadingList,"type":_vm.sort ? 'sort' : item.type},on:{"update:type":function($event){return _vm.updateEventType(key, $event)},"remove":function($event){return _vm.removeEvent(key)},"save":function($event){return _vm.setUnloadingList()}}})],1)}),1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }