<template>
  <div>
    <!-- <v-combobox
      v-if="type === 'edit'"
      v-model="eventGroups"
      :items="filteredGroup"
      placeholder="選擇執行對象"
      outlined
      dense
      multiple
      item-text="name"
      item-value="id"
      height="136px"
      class="mb-2"
      single-line
      hide-details
      @change="commit()"
    >
    </v-combobox> -->
    <v-card elevation="0" outlined class="mb-2">
      <div class="text-right mb-n2" v-if="type === 'edit'">
        <v-tooltip top color="grey lighten-1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ma-1" v-bind="attrs" v-on="on" color="warning">
              mdi-information
            </v-icon>
          </template>
          <span><GroupAppliedHint /></span>
        </v-tooltip>
      </div>
      <v-list-item-group
        v-if="type === 'edit'"
        v-model="eventGroups"
        multiple
        class="group-list"
        @change="commit()"
      >
        <v-list-item
          v-for="(group, key) in filteredGroup"
          :key="key"
          :value="group"
        >
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex">
                <div>
                  {{ group.name }}
                </div>
                <v-spacer></v-spacer>
                <GroupApply :group="group" />
              </div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
      <v-list v-else dense class="group-list">
        <v-list-item v-for="(group, key) in eventGroups" :key="key">
          <v-list-item-icon class="mr-0">
            <v-icon color="accent">mdi-circle-small</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="accent--text subtitle-1"
              >{{ group.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GroupAppliedHint from '@/views/school/groupApplied/Hint'
import GroupApply from '@/views/school/groupApplied/Apply'

export default {
  name: 'UnloadingEventGroup',
  mixins: [],
  components: { GroupAppliedHint, GroupApply },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'show'
    },
    hideGroups: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      eventGroups: this.value
    }
  },
  computed: {
    ...mapGetters({
      groups: 'groups/groups'
    }),
    tagId() {
      return this.$route.params.tagId
    },
    filteredGroup() {
      if (!this.groups) {
        return []
      }
      return this.groups.filter(({ id, countMap }) => {
        return countMap.AIRCONDITIONER > 0 && !this.hideGroups.includes(id)
      })
    }
  },
  watch: {
    value() {
      this.initGroups()
    },
    filteredGroup() {
      this.initGroups()
    }
  },
  created: function () {},
  mounted: function () {
    if (!this.groups) {
      this.getGroups(this.tagId)
    }
    this.initGroups()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getGroups: 'groups/getGroups'
    }),
    initGroups() {
      if (!this.groups || !this.value) {
        return
      }
      this.eventGroups = this.groups.filter((item) => {
        return this.value.includes(item.id)
      })
    },
    commit() {
      const selectedGroupsId = this.eventGroups.map((item) => item.id)
      this.$emit('input', selectedGroupsId)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .group-list {
    height: 136px;
    overflow: auto;
  }
}
</style>
