<template>
  <div class="ma-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center">
        <v-col cols="auto" class="pr-0"> 警戒卸載值： </v-col>
        <v-col cols="auto" style="width: 100px">
          <v-text-field
            dense
            solo
            v-model="demand.alarmcapacity"
            :rules="[
              (v) => !demand.recoverycapacity || !!v || '必填',
              (v) => !isNaN(v) || '請輸入數字',
              (v) =>
                Number(demand.recoverycapacity) < Number(v) || '須大於復歸值'
            ]"
            :disabled="!demandEdit"
            class="mt-5"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="pl-0"> kW </v-col>
        <v-col cols="auto" class="pr-0"> 復歸值： </v-col>
        <v-col cols="auto" style="width: 100px">
          <v-text-field
            dense
            solo
            v-model="demand.recoverycapacity"
            :rules="[
              (v) => !demand.alarmcapacity || !!v || '必填',
              (v) => !isNaN(v) || '請輸入數字',
              (v) => Number(demand.alarmcapacity) > Number(v) || '須小於警戒值'
            ]"
            :disabled="!demandEdit"
            class="mt-5"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="pl-0"> kW </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="demandEdit"
            color="success"
            :disabled="!valid"
            @click="
              setDemand()
              demandEdit = false
            "
            >儲存
          </v-btn>
          <v-btn v-else color="primary" @click="demandEdit = true">修改 </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div class="mt-6">
      <v-row align="center" justify="space-between">
        <v-col cols="auto">
          <v-btn
            color="primary"
            text
            x-large
            class="pa-0"
            style="height: auto"
            @click="createEvent()"
            :disabled="sort"
          >
            <v-icon class="mr-1">mdi-plus-circle-outline</v-icon> 新增卸載方案
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="sort = !sort"
            :disabled="disabledSortBtn"
          >
            {{ sort ? '關閉執行順序' : '編輯執行順序' }}
          </v-btn>
        </v-col>
      </v-row>
      <draggable v-model="unloadingList" v-bind="dragOptions" @end="setSort()">
        <transition-group type="transition" class="row">
          <v-col
            v-for="(item, key) in unloadingList"
            cols="12"
            sm="6"
            lg="4"
            xl="3"
            :key="`${key}`"
          >
            <eventCard
              :value="item"
              :unloadingList="unloadingList"
              :type="sort ? 'sort' : item.type"
              @update:type="updateEventType(key, $event)"
              @remove="removeEvent(key)"
              @save="setUnloadingList()"
            />
          </v-col>
        </transition-group>
      </draggable>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MeterOffLoading from '@/api/ems/MeterOffLoading'
import draggable from 'vuedraggable'
import eventCard from '@/views/school/unloading/eventCard'

export default {
  name: undefined,
  mixins: [],
  components: {
    draggable,
    eventCard
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      valid: true,
      overlay: false,
      demandEdit: false,
      demand: {
        alarmcapacity: null,
        recoverycapacity: null
      },
      sort: false,
      type: 'view',
      unloadingList: []
    }
  },
  computed: {
    dragOptions() {
      return {
        selectedClass: 'selected', // The class applied to the selected items
        animation: 200,
        disabled: !this.sort
      }
    },
    disabledSortBtn() {
      if (!this.unloadingList) {
        return true
      }
      const inEditUnloading = this.unloadingList.find((event) => {
        return ['edit'].includes(event.type)
      })
      const isInEdit = Boolean(inEditUnloading)
      return isInEdit
    }
  },
  watch: {
    sort(val) {
      this.type = val ? 'sort' : 'view'
    },
    'demand.alarmcapacity'() {
      this.$refs.form.resetValidation()
    },
    'demand.recoverycapacity'() {
      this.$refs.form.resetValidation()
    }
  },
  created: function () {},
  mounted: function () {
    this.getDemand()
    this.getUnloadingList()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show'
    }),
    getDemand() {
      MeterOffLoading.getDemand(this.tagId)
        .then((response) => {
          this.demand.alarmcapacity = response.data.alarmcapacity
          this.demand.recoverycapacity = response.data.recoverycapacity
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {})
    },
    setDemand() {
      const validate = this.$refs.form.validate()
      if (!validate) {
        return
      }
      this.overlay = true
      MeterOffLoading.setDemand(this.tagId, this.demand)
        .then((response) => {
          console.log(response)
          this.showMsg({
            message: `設定成功`,
            closable: true,
            time: 2000
          })
        })
        .catch((error) => {
          console.log(error.response)
          this.showMsg({
            message: '設定失敗',
            closable: true,
            time: 2000
          })
        })
        .finally(() => {
          this.overlay = false
        })
    },
    getUnloadingList() {
      this.overlay = true
      MeterOffLoading.getPlan(this.tagId)
        .then((response) => {
          this.unloadingList = response.data
            .sort((item) => {
              return item.priority
            })
            .map((item) => {
              item.type = 'view'
              return item
            })
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.overlay = false
        })
    },
    setUnloadingList() {
      this.overlay = true
      MeterOffLoading.setPlan(this.tagId, this.unloadingList)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error.response)
          this.showMsg({
            message: error.response.data.errorMessage || '儲存失敗',
            closable: true,
            time: 2000
          })
        })
        .finally(() => {
          this.overlay = false
        })
    },
    createEvent() {
      this.unloadingList.push({
        name: '',
        action: {},
        groupId: [],
        type: 'edit',
        priority: this.unloadingList.length + 1
      })
    },
    updateEventType(key, type) {
      this.unloadingList[key].type = type
      const inEditUnloading = Boolean(
        this.unloadingList.find((event) => {
          return ['edit'].includes(event.type)
        })
      )
      this.type = inEditUnloading ? 'edit' : 'view'
    },
    removeEvent(key) {
      this.unloadingList.splice(key, 1)
      this.setSort()
    },
    setSort() {
      console.log(JSON.parse(JSON.stringify(this.unloadingList)))
      this.unloadingList = this.unloadingList.map((item, key) => {
        item.priority = key + 1
        return item
      })
      console.log(JSON.parse(JSON.stringify(this.unloadingList)))
      this.setUnloadingList()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .sortable-ghost {
    .v-card {
      opacity: 0.5;
      background: #aaaaaaff;
      .v-toolbar.v-sheet {
        background-color: transparent;
      }
    }
  }
}
</style>
